import { findBundleContentsId } from './microdata'
import { frameFactory } from './frame-factory'
import Modal from './modal'
import { onChildEvent } from './events'

export default class Bundle {
  constructor (options = {}) {
    options.type = 'bundle'

    this.container = options.container ||
      document.querySelector('provenance-bundle') ||
      document.getElementById(options.id)

    if (!this.container) {
      console.warn('ProvenanceSDK: Bundle container not found.')
      return
    }

    if (this.isDataDrivenEmbed(options)) {
      const bundleContentsId = findBundleContentsId(options.schema)
      this.createFromBundleContentsId(options, bundleContentsId)
    } else {
      this.createFromUrl(options)
    }
  }

  isDataDrivenEmbed ({ url, schema }) {
    if (schema) return true

    const urlComponents = new URL(url).pathname.split('/')
    return !urlComponents.includes('product') && !urlComponents.includes('user')
  }

  createFromUrl (options) {
    this.renderZoidComponent(this.container, options.url)
  }

  createFromBundleContentsId (options, bundleContentsId) {
    if (bundleContentsId) {
      options.url += '/' + bundleContentsId
      this.createFromUrl(options)
    } else {
      // If no ID is found, it may be that the schema has not yet been inserted into the page
      // Wait for 3 seconds and try looking for it again
      setTimeout(() => {
        const id = findBundleContentsId(options.schema)

        if (id) {
          options.url += '/' + id
          this.createFromUrl(options)
        } else {
          // If we can't find an ID after the 3 second wait, treat it as an error
          console.warn(`ProvenanceSDK: Could not find bundle contents ID from ${options.schema} microdata`)
        }
      }, options.retryTimeoutMs || 3000)
    }
  }

  renderZoidComponent (container, url) {
    const options = {
      url: url,
      version: VERSION,
      onChildEvent: (event = {}) => { onChildEvent(event, container, this.component.onParentEvent) },
      openModal: (options = {}) => { this.openModal(options) }
    }

    const frame = frameFactory.getFrame('embed', url)
    this.component = frame.component(options)
    this.embed = this.component.render(container).catch((error) => {
      console.error(error)
    })
  }

  openModal (options) {
    this.modal = new Modal({ frameFactory: this.frameFactory })
    this.modal.open(options)
  }
}
