/**
 *  Analytics functions for any customer who uses GTM
 */

class Analytics {
  listenForImpressions (container, onParentEvent) {
    window.addEventListener('scroll', window.provenanceOnScroll = () => {
      this.checkImpression(container, onParentEvent)
    })
    this.checkImpression(container, onParentEvent)
  }

  checkImpression (container, onParentEvent) {
    if (this.isAnImpression(container)) {
      onParentEvent({ name: 'impression_detected' })

      // remove event listener so we only send impression once
      window.removeEventListener('scroll', window.provenanceOnScroll)
    }
  }

  // push custom events to data layer
  track (eventData) {
    // catch errors for employees who have GTM blocked
    try {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(eventData)
    } catch (error) {
      console.error('ProvenanceSDK: ', error.message)
    }
  }

  /**
   *  Helper functions for analytics
  */

  /**
  *  Check if element is in viewport
  *  see: https://gomakethings.com/how-to-test-if-an-element-is-in-the-viewport-with-vanilla-javascript/
  */

  isInViewport (elem) {
    const bounding = elem.getBoundingClientRect()
    // check top of embed element is below the top of the viewport
    // and that 50% of the embed element is above the bottom of the viewport
    return (
      bounding.top >= 0 &&
      (bounding.bottom - (elem.offsetHeight / 2)) <=
      (window.innerHeight || document.documentElement.clientHeight)
    )
  }

  /**
  *  Check if element is visible
  *  see: https://davidwalsh.name/offsetheight-visibility
  */

  isVisible (elem) {
    return elem.offsetHeight > 0
  }

  isAnImpression (elem) {
    return this.isInViewport(elem) && this.isVisible(elem)
  }
}

export const analytics = new Analytics()
