/* eslint-disable no-new */

import Experience from './experience'
import Bundle from './bundle'
import css from './modal.min.css'

// Note: the [url] bit is important in this CSS selector because URL is a
// required parameter when using the SDK as a <script> tag.
// It also ensures the embed won't be loaded twice when using the SDK
// as a JS API.
const SELECTOR = 'provenance-experience[url],provenance-bundle[url],.provenance-Embed'

// Initialize all embeds matching SELECTOR
export function mountAll () {
  document.querySelectorAll(SELECTOR).forEach(node => {
    if (node.tagName === 'PROVENANCE-EXPERIENCE') {
      const url = node.getAttribute('url')
      const schema = node.getAttribute('schema')
      new Experience({ container: node, url, schema })
    } else if (node.tagName === 'PROVENANCE-BUNDLE') {
      const url = node.getAttribute('url')
      const schema = node.getAttribute('schema')
      new Bundle({ container: node, url, schema })
    // Legacy embed code format
    } else if (node.classList.contains('provenance-Embed')) {
      // See if it is a story image embed
      const href = node.getAttribute('href')
      const embedWrapper = document.createElement('div')
      node.replaceWith(embedWrapper)
      if (href) {
        new Experience({ container: embedWrapper, url: href + `/embed?variant=${node.dataset.variant}` })
      } else {
        const scriptEl = document.getElementById('provenance-embedScript')
        const { url } = scriptEl.dataset
        console.log('provenance-Embed', url)
        new Bundle({ container: embedWrapper, url })
      }
    }
  })
}

// Remove all embeds matching SELECTOR from DOM
export function unmountAll () {
  document.querySelectorAll(SELECTOR).forEach(node => {
    node.remove()
  })
}

// CSS from app needs to be injected in the parent page for modals to display
document.head.insertAdjacentHTML('beforeend', `<style>${css}</style>`)

mountAll()

console.warn('Using Provenance SDK v0.8.22. Please upgrade to the latest Provenance SDK for a better experience: https://www.npmjs.com/package/@provenance/provenance-sdk')

export { Bundle, Experience }
