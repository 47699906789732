import zoid from 'zoid'

class FrameFactory {
  constructor () {
    this.frameComponents = {}
  }

  hashCode (s) {
    return Math.abs(s.split('').reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0))
  }

  getFrame (variant = 'embed', url = null) {
    const provenanceUrl = (window.provenance && window.provenance.rootUrl) || 'https://www.provenance.org'

    if (url == null) {
      url = `${provenanceUrl}/x`
    }
    const key = `${variant}-${this.hashCode(url)}`

    console.debug(`SDKv${VERSION}:FrameFactory`, variant, url, key)

    if (this.frameComponents[key]) {
      return this.frameComponents[key]
    }

    let height = '100%'
    let autoResizeHeight = true
    const width = '100%'

    switch (variant) {
      case 'small':
        height = '524px'
        break
      case 'story':
        break
      case 'full':
        autoResizeHeight = false
        break
    }

    const component = zoid.create({
      tag: `provenance-experience-${key}`,
      url,
      dimensions: {
        height: height,
        width: width
      },
      autoResize: {
        height: autoResizeHeight,
        width: false
      },
      props: {
        url: {
          type: 'string',
          required: true
        },
        version: {
          type: 'string',
          required: false
        },
        openModal: {
          type: 'function',
          required: false
        }
      },
      prerenderTemplate: function containerTemplate ({ doc }) {
        const html = doc.createElement('html')
        const div = doc.createElement('div')
        div.style.width = width
        div.style.height = height
        html.appendChild(div)
        return html
      },
      // The slightly odd setup for exports is necessary so that we wait until the component is finishing rendering
      // See https://github.com/krakenjs/zoid/blob/master/docs/api/xprops.md#xpropsexport--string--any---promisevoid
      exports: ({ getExports }) => {
        return {
          onParentEvent: (event) => getExports().then(exports => exports.onParentEvent(event))
        }
      }
    })
    this.frameComponents[key] = {
      component: component,
      class: `ProvenanceModal--${variant}Variant`
    }
    return this.frameComponents[key]
  }
}

export const frameFactory = new FrameFactory()
