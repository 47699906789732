import { analytics } from './analytics'

export function onChildEvent (event, container, onParentEvent) {
  switch (event.name) {
    case 'setup_impression_tracking':
      analytics.listenForImpressions(container, onParentEvent)
      break
    case 'analytics_event':
      analytics.track(event.data.details)
      break
    default:
      console.warn('[SDK] Unrecognised event:', event)
  }
}
