const BRAND_SCHEMA_SELECTOR = '[itemtype="https://schema.org/Brand"],[itemtype="http://schema.org/Brand"]'
const PRODUCT_SCHEMA_SELECTOR = '[itemtype="https://schema.org/Product"],[itemtype="http://schema.org/Product"]'

const BRAND_IDENTIFIERS = ['identifier']
const PRODUCT_IDENTIFIERS = ['productID', 'sku', 'gtin', 'gtin12', 'gtin13', 'gtin14', 'gtin8', 'mpn', 'isbn']

export function findBundleContentsId (schemaType) {
  if (schemaType === 'Brand') {
    return brandSchemaId() || brandIdFromJsonLd()
  } else if (schemaType === 'Product') {
    return productSchemaId() || productIdFromJsonLd()
  } else {
    return productSchemaId() || productIdFromJsonLd() || brandSchemaId() || brandIdFromJsonLd()
  }
}

function brandSchemaId () {
  const el = document.querySelector(BRAND_SCHEMA_SELECTOR)
  const id = el && findIdFromSchema(el, BRAND_IDENTIFIERS)
  return id ? `user/${encodeURIComponent(id)}` : null
}

function productSchemaId () {
  const el = document.querySelector(PRODUCT_SCHEMA_SELECTOR) || document
  const id = findIdFromSchema(el, PRODUCT_IDENTIFIERS)
  return id ? `product/${encodeURIComponent(id)}` : null
}

function findIdFromSchema (schema, fields) {
  for (const field of fields) {
    const selector = schema.querySelector(`[itemprop~=${field}]`)
    if (selector !== null) {
      if (selector.textContent) {
        return selector.textContent
      }
      // Used to check meta tags - see https://schema.org/docs/gs.html#advanced
      if (selector.content) {
        return selector.content
      }
    }
  }
}

function productIdFromJsonLd () {
  for (const obj of jsonLdObjects()) {
    if (obj['@type'] !== 'Product') continue

    const key = PRODUCT_IDENTIFIERS.find(key => obj[key])
    if (key) {
      return `product/${encodeURIComponent(obj[key])}`
    }
  }
  return null
}

function brandIdFromJsonLd () {
  for (const obj of jsonLdObjects()) {
    if (obj['@type'] !== 'Brand') continue

    const key = BRAND_IDENTIFIERS.find(key => obj[key])
    if (key) {
      return `user/${encodeURIComponent(obj[key])}`
    }
  }
  return null
}

/**
 * Checks for one or more `<script type="application/ld+json">` in the page.
 * @returns {Array} of parsed JSON-LD objects
 */
export function jsonLdObjects () {
  return Array.from(document.querySelectorAll('[type="application/ld+json"]'))
    .flatMap(script => {
      try {
        return [JSON.parse(script.textContent)]
      } catch (e) {
        return []
      }
    })
}
