/* eslint-disable no-prototype-builtins */

import { frameFactory } from './frame-factory'
import Modal from './modal'
import { onChildEvent } from './events'
import { findBundleContentsId } from './microdata'

export default class Experience {
  constructor (options = {}) {
    this.container = options.container ||
      document.querySelector('provenance-experience') ||
      document.getElementById(options.id)
    if (!this.container) {
      console.warn('ProvenanceSDK: Experience container not found.')
      return
    }

    if (options.schema) {
      this.createFromSchema(options)
      return
    }

    if (options.url) {
      this.renderZoidComponent(this.container, options.url)
      return
    }

    // Only attempt to render via passport lookup if we haven't already rendered
    const domain = options.domain || 'www.provenance.org'
    this.findPassportId(options).then(passportId => {
      const url = this.constructModalUrl(domain, passportId, options.countryCode)
      this.renderZoidComponent(this.container, url)
    })
  }

  createFromSchema (options) {
    const bundleContentsId = findBundleContentsId(options.schema)
    if (bundleContentsId == null) {
      console.warn(`ProvenanceSDK: Could not find bundle contents ID from ${options.schema} microdata`)
      return
    }
    options.url += '/' + bundleContentsId
    this.renderZoidComponent(this.container, options.url)
  }

  renderZoidComponent (container, url) {
    const options = {
      url: url,
      version: VERSION,
      onChildEvent: (event = {}) => { onChildEvent(event, container, this.component.onParentEvent) },
      openModal: (options = {}) => { this.openModal(options) }
    }
    const frame = frameFactory.getFrame('embed', url)
    this.component = frame.component(options)
    this.embed = this.component.render(container).catch((error) => {
      console.error(error)
    })
  }

  openModal (options) {
    this.modal = new Modal()
    this.modal.open(options)
  }

  async findPassportId (options) {
    if (options.passportId) {
      return options.passportId
    }

    const apiDomain = options.apiDomain || 'api.provenance.org'

    let url = `https://${apiDomain}/v1/products`
    if (options.gtin) {
      url += `?gtin=${options.gtin}`
    } else if (options.batch) {
      url += `?batch=${options.batch}`
    }
    const response = await fetch(url, { headers: { 'X-API-KEY': options.apiKey } })
    const data = await response.json()
    return data.id
  }

  constructModalUrl (domain, passportId, countryCode) {
    let url = `https://${domain}/products/${passportId}/modal`

    if (countryCode) {
      url += `?country_code=${countryCode}`
    }

    return url
  }
}
